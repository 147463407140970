import { useEffect } from "react"

// This is used to open the app in a new tab
// when the user clicks on the social buttons within the embed pages

export const useForceSSONewTab = (isEmbed: boolean) => {
  useEffect(() => {
    if (isEmbed) {
      setTimeout(() => {
        document
          .querySelectorAll(".cl-socialButtonsBlockButton")
          .forEach((button) => {
            button.addEventListener("click", (e) => {
              e.preventDefault()
              e.stopPropagation()
              window.open("https://app.tryspecter.com", "_blank")
            })
          })
      }, 1000)
    }
  }, [isEmbed])
}
